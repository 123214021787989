import React from 'react'
import { useSelector } from 'react-redux'
import sanitizeHtml from 'sanitize-html'
import { ALLOWED_ATTRIBUTES, ALLOWED_TAGS } from '../conf/constants'

export default function Subreward(props) {
  const { reward, chooseReward, selected, disabled } = props
  const content = useSelector((state) => state.translation.content)
  const isSelected = selected === reward.id
  const isDisabled = selected !== null && disabled

  return (
    <div
      className={`subreward${isSelected ? ' selected' : ''}${
        isDisabled ? ' disabled' : ''
      }`}
    >
      <div>
        <h3>
          {sanitizeHtml(content['content.' + reward.title], {
            allowedTags: [],
            allowedAttributes: [],
          })}
        </h3>
        <div
          dangerouslySetInnerHTML={{
            __html: sanitizeHtml(content['content.' + reward.description], {
              allowedTags: [],
              allowedAttributes: [],
            }),
          }}
        ></div>
        <div
          dangerouslySetInnerHTML={{
            __html: sanitizeHtml(content['content.' + reward.image], {
              allowedTags: ALLOWED_TAGS,
              allowedAttributes: ALLOWED_ATTRIBUTES,
            }),
          }}
        ></div>
        <br />
      </div>
      <button
        className={`btn btn--primary${isSelected ? ' btn--item_selected' : ''}`}
        disabled={isSelected}
        onClick={() => chooseReward(reward.id)}
      >
        {isSelected ? 'Odměna vybrána' : 'Vybrat odměnu'}
      </button>
    </div>
  )
}
